/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ScrollToTop from "../components/menu/ScrollToTop"
import Header from "./menu/header"
import Footer from "../components/footer"
// import "./layout.css"

import "modern-normalize/modern-normalize.css"
import "../bootstrap.min.css"
import "../styles.scss"

import splitbee from "@splitbee/web"

splitbee.init({
  disableCookie: true,
  //token: "MWZ6PQ8TLO3Q",
})

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {/*<div class="col-12 mx-auto">*/}
      <main>{children}</main>
      {/*</div>*/}
      <Footer />
      <ScrollToTop />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
