import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"
import { useStaticQuery, graphql } from "gatsby"
import Obfuscate from "react-obfuscate"

export default () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            claim
            phone
            email
            author
          }
        }
      }
    `
  )

  return (
    <footer className="container-fluid black_more">
      <div className="row m-10-hor">
        <div className="col-md-4">
          <div className="footer-col">
            <h4 className="heading">{data.site.siteMetadata.title}</h4>
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.site.siteMetadata.claim,
              }}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="footer-col">
            <h4 className="heading">Nuestros Productos</h4>
            <div className="content">
              <Link className="link" to="/muebles/">
                Muebles de Atrezzo
              </Link>
              <Link className="link" to="/grupos/">
                Grupos Electrógenos
              </Link>
              <Link className="link" to="/transporte/">
                Transporte para Rodajes
              </Link>
              {/*<div className="link">
                Iluminación para interiores y exteriores
              </div>
            <div className="link">Arcades y Máquinas de Juegos</div>*/}
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="footer-col">
            <img
              src={logo}
              className="logo-footer"
              alt={"Logo " + data.site.siteMetadata.title}
              height="138"
              width="136"
            />
            <ul>
              <li>
                <i className="fa fa-map-marker"></i>
                <a
                  href="//goo.gl/maps/V3GvSAFA64ymCxW8A"
                  target="_blank"
                  rel="noreferrer"
                >
                  Traves&iacute;a de la Palmera 18, 28500 Arganda del Rey
                </a>
              </li>
              <li>
                <i className="fa fa-envelope-o"></i>
                <Obfuscate email={data.site.siteMetadata.email} />
              </li>
              <li>
                <i class="fa fa-whatsapp" aria-hidden="true"></i>
                <Obfuscate
                  className="phone"
                  tel={data.site.siteMetadata.phone}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="subfooter">
        <div className="row m-10-hor">
          <div className="col-md-10">
            <div className="content">
              {new Date().getFullYear()} {data.site.siteMetadata.title} |{" "}
              <Link to="/aviso-legal/">Aviso Legal</Link> -{" "}
              <Link to="/politica-privacidad/">
                Pol&iacute;tica de Privacidad
              </Link>
            </div>
          </div>
          <div className="col-md-2">
            <div className="content-right">
              <a
                href="//jatstudio.com"
                rel="nofollow noreferrer"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 104.3 117.3"
                >
                  <path
                    fill="#f5f5f5"
                    d="M94.3 29.1L56.8 7.4c-3.2-1.8-6-1.9-9.2 0L10 29.1c-3.2 1.8-4.6 4.3-4.6 8V64.8L27 52.4v20.8l7.2 4.2V39.9L12.6 52.4V41.1c0-3.8 1.4-6.2 5.1-8.2l8.7-5 22.2-12.8v87.2L16.5 83.7c-2.4-1.8-3.5-3.5-3.9-6.5v-12l-7.2 4.2v12c.2 3.2 1.7 5.3 4.6 7l37.6 21.7c3.4 1.5 5.8 1.5 9.2 0l37.6-21.7c3.2-1.8 4.6-4.3 4.6-8V56.6L70.2 39.9v8.3l21.5 12.5V77c-.4 3.1-1.4 4.9-3.9 6.8l-32.1 18.5V64.9l14.4 8.3v4.2l10.8-6.2-25.1-14.6V15.1l.3.1L72 24.4l15.9 9.2.2.1L99 40v-2.9c-.1-3.7-1.5-6.1-4.7-8"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
