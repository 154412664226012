import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Obfuscate from "react-obfuscate"

import logo from "../../images/logo.svg"

export default function () {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            phone
          }
        }
      }
    `
  )

  useEffect(() => {
    const header = document.getElementById("myHeader")
    const totop = document.getElementById("scroll-to-top")
    const sticky = header.offsetTop
    const scrollCallBack = window.addEventListener("scroll", () => {
      //btn_icon(false)
      if (window.pageYOffset > sticky + 300) {
        header.classList.add("sticky")
        totop.classList.add("show")
      } else {
        header.classList.remove("sticky")
        totop.classList.remove("show")
      }
      /*if (window.pageYOffset > sticky) {
        closeMenu()
      }*/
    })
    return () => {
      window.removeEventListener("scroll", scrollCallBack)
    }
  }, [])
  return (
    <header id="myHeader" className="navbar">
      <Link to="/" title={data.site.siteMetadata.description} className="logo">
        <img
          src={logo}
          className="img-fluid"
          alt={"Logo " + data.site.siteMetadata.title}
          height="138"
          width="136"
        />
      </Link>

      <nav>
        <Link activeClassName="active" to="/muebles/">
          Mobiliario de Atrezzo
        </Link>
        <Link activeClassName="active" to="/grupos/">
          Grupos Electr&oacute;genos
        </Link>
        <Link activeClassName="active" to="/transporte/">
          Transporte para Rodajes
        </Link>
      </nav>

      <div>
        <Link className="contact" to="/contacto/#contact">
          Contacto
        </Link>
        <Obfuscate tel={data.site.siteMetadata.phone} aria-label="Phone Number">
          <svg
            height="36"
            width="36"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 418.135 418.135"
          >
            <g>
              <path d="M198.929.242C88.5 5.5 1.356 97.466 1.691 208.02c.102 33.672 8.231 65.454 22.571 93.536L2.245 408.429c-1.191 5.781 4.023 10.843 9.766 9.483l104.723-24.811c26.905 13.402 57.125 21.143 89.108 21.631 112.869 1.724 206.982-87.897 210.5-200.724C420.113 93.065 320.295-5.538 198.929.242zm124.957 321.955c-30.669 30.669-71.446 47.559-114.818 47.559-25.396 0-49.71-5.698-72.269-16.935l-14.584-7.265-64.206 15.212 13.515-65.607-7.185-14.07c-11.711-22.935-17.649-47.736-17.649-73.713 0-43.373 16.89-84.149 47.559-114.819 30.395-30.395 71.837-47.56 114.822-47.56 43.372.001 84.147 16.891 114.816 47.559 30.669 30.669 47.559 71.445 47.56 114.817-.001 42.986-17.166 84.428-47.561 114.822z" />
              <path d="M309.712 252.351l-40.169-11.534a14.971 14.971 0 00-14.816 3.903l-9.823 10.008c-4.142 4.22-10.427 5.576-15.909 3.358-19.002-7.69-58.974-43.23-69.182-61.007-2.945-5.128-2.458-11.539 1.158-16.218l8.576-11.095a14.97 14.97 0 001.847-15.21l-16.9-38.223c-4.048-9.155-15.747-11.82-23.39-5.356-11.211 9.482-24.513 23.891-26.13 39.854-2.851 28.144 9.219 63.622 54.862 106.222 52.73 49.215 94.956 55.717 122.449 49.057 15.594-3.777 28.056-18.919 35.921-31.317 5.362-8.453 1.128-19.679-8.494-22.442z" />
            </g>
          </svg>
        </Obfuscate>
      </div>
    </header>
  )
}
